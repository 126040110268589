import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.jpg";
import appleLogo from "images/apple-icon.png";
import charatersImage from "../images/dwts-characters.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { apiRequest } from "helpers/ApiRequest";
import { useHistory } from "react-router-dom";
import cookieService, {
  accessToken,
  refreshToken,
  setCookie,
} from "helpers/TokenService";
import axios from "axios";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default (
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Delete Your Account",
  socialButtons = [
    {
      iconImageSrc: appleLogo,
      text: "Sign In With Apple",
      url: "https://www.youtube.com/watch?v=xvFZjo5PgG0",
    },
  ],
  submitButtonText = "Delete Account",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "#",
  signupUrl = "/register"
) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);

  const history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault();
    setErrorMessage(null);
    const res = await fetch(
      "https://dwtd-sleep.playsidestudios-devel.com/v1/account/auth/login",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      }
    );

    const output = await res.json();

    if (res.status !== 200) {
      setErrorMessage(output.error);
      return;
    }
    handleSendSlackMessage()
  }

  async function handleSendSlackMessage() {
    const webhookUrl =
      "https://hooks.slack.com/services/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";

    const data = {
      text: `${email} has requested their account be deleted.`,
    };

    let res = await axios.post(webhookUrl, JSON.stringify(data), {
      withCredentials: false,
    });

    console.log(res);
    if (res.status === 200) {
      setSuccessMessage(true);
    } else {
      setErrorMessage("There was an error.  Please try again later.");
    }
  }

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              {errorMessage && (
                <center>
                  <div className="justify-content-center">
                    <div className="alert alert-danger" role="alert">
                      An error occured: {errorMessage}
                    </div>
                  </div>
                </center>
              )}

              {successMessage === true && (
                <center>
                  <div className="justify-content-center">
                    <div className="alert alert-success" role="alert">
                      Successfully requested account deletion.
                    </div>
                  </div>
                </center>
              )}
              <Heading>{headingText}</Heading>
              <FormContainer>
                <Form>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onInput={(e) => setEmail(e.target.value)}
                  />
                  <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onInput={(e) => setPassword(e.target.value)}
                  />
                  <SubmitButton type="submit" onClick={handleSubmit}>
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={charatersImage} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
