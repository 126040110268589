import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
// import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import macHeroScreenshotImageSrc from "images/dwts-phone.png";

import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-crunold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      {/* <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
      /> */}
      {/* <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      /> */}
      <FeatureWithSteps
        subheading={<Subheading>GET THE APP</Subheading>}
        heading={
          <>
            <span tw="font-crunold">
              Download <HighlightedText>today!</HighlightedText>
            </span>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        id="downloadSteps"
      />
      {/* <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our{" "}
            <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description:
              "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description:
              "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      /> */}
      {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: [
              "30 Templates",
              "7 Landing Pages",
              "12 Internal Pages",
              "Basic Assistance",
            ],
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: [
              "60 Templates",
              "15 Landing Pages",
              "22 Internal Pages",
              "Priority Assistance",
            ],
            featured: true,
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: [
              "90 Templates",
              "27 Landing Pages",
              "37 Internal Pages",
              "Personal Assistance",
            ],
          },
        ]}
      /> */}
      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc.",
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC",
          },
        ]}
      /> */}
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={<>Check out our FAQ</>}
        description={
          <>
            Your issue wasnt convered in the below FAQ? Use the embedded zendesk
            widget or{" "}
            <HighlightedText>
              {/* window.open("mailto:someone@example.com?subject=Test%20Email&body=First%20Name:%20"+fname+"%20Last%20Name:"+ln */}
              <a
                target="_blank"
                href="mailto:support@dumbwaystosleep.zendesk.com?subject=Support [via Website]&body=%0D%0A%20%0D%0A%20%0D%0A%20[Contacted via website mailto]">
                Email our friendly support!
              </a>
            </HighlightedText>
          </>
        }
        id="FAQ"
        faqs={[
          {
            question: "Is Dumb Ways to Sleep made for a specific age?",
            answer:
              "Dumb Ways to Sleep content is suited for children of all ages, from 0-10+.\nDumb Ways to Sleep features all types of Sounds such as white and colour noise tracks perfect for babies.\nInfants and toddlers are better equipped to calm down, deal with changes, and learn important social-emotional skills by listening to Music, Meditations and Stories.\nChildren age 5-10 will enjoy all areas of Dumb Ways to Sleep, from stories through to breathing excercises.",
          },
          {
            question: "When should I listen to Dumb Ways to Sleep?",
            answer:
              "Dumb Ways to Sleep is a safe space for you and your child to navigate little moments. Whether it’s trouble sleeping, a first day of school or simply creating 5 minutes of calm, Dumb Ways to Sleep will be there for you. You can use Dumb Ways to Sleep at any time of the day, from music in the mornings to some of your favorite Sleep stories with Klutz and Bozo for your bedtime routine. Meditations and breathing exercises will help your children relax and calm down after a challenging moment or whenever they need it the most.",
          },
          {
            question: "What does a subscription include?",
            answer:
              "Designed with both parents and kids in mind, Dumb Ways to Sleep features over 100 unique pieces of calming content ideal for settling kids down for a more restful nights sleep, helping promote mindfulness and emotion resilience. Relaxing audio stories with their favorite lovable characters that will help them drift off to sleep. Guided meditations and deep breathing exercises brought to you by our internationally renowned child mindfulness and positive psychology experts ‘Peaceful Kids’.",
          },
          {
            question: "How can I cancel my subscription?",
            answer:
              "You can cancel your subscription plan, by following the steps below: If you are using an Apple device details of how to cancel your subscription can be found here – https://support.apple.com/en-gb/HT202039. If you are using a Google device details of how to cancel your subscription can be found here – https://support.google.com/googleplay/answer/7018481?co=GENIE.Platform%3DAndroid&hl=en You will need to ensure that you follow the directions and have canceled your Subscription plan to ensure that no further fees are charged. Important: Please note that for all platforms, subscriptions renew automatically unless auto-renew is turned off at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period or trial",
          },
          {
            question:
              "I bought a subscription on my Android phone. Will it unlock on my Android tablet?",
            answer:
              "Subscription purchases are tied to the primary Google account that you set up in the Google Play store when making the purchase. After you subscribe, you will be asked to create a Dumb Ways to Sleep account. As long as you login with the same account on all of your devices, the content should unlock. In rare cases it might take up to 24 hours for the purchase to be applied across all devices. If the content appears locked after logging in, open the Settings menu (from the top left of the home screen), select Manage subscription and tap “Restore Subscription”. Note that having two Google Play accounts on your device may cause issues.",
          },
          {
            question:
              "I have a subscription on an iPhone. Can I use it on an Android device?",
            answer:
              "Yes, when you subscribe to Dumb Ways to Sleep and register using your email and password from the Settings screen in the app, your subscription will then unlock Dumb Ways to Sleep as long as those devices are within the list of devices supported.",
          },
          {
            question:
              "What is the device compatibility for Dumb Ways to Sleep?",
            answer:
              "You can install Dumb Ways to Sleep on the following devices: Apple devices with Minimum iOS 12.0 or above. Android devices with Android NOUGAT (7.0) or above.",
          },
          {
            question: "What do I do if I forget my email?",
            answer:
              "If you cannot remember your login details, please contact us at support@dumbwaystosleep.com  and we will help you to log back in.",
          },
          {
            question: "How do I log out?",
            answer:
              "If you want to Log out from your current device: Tap on the Settings icon on the app home screen. -> Tap “Log out”.",
          },
        ]}
      />
      {/* <GetStarted /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
