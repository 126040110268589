import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default () => {
  const [showingAndroid, setShowingAndroid] = useState(false);
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow
            style={{
              justifyContent: "space-between",
            }}
          >
            <Heading>
              {showingAndroid
                ? "Manage Subscription - Android"
                : "Manage Subscription - iOS"}
            </Heading>
            <Menu as="div" className="relative inline-block text-left">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Menu.Button
                  style={{
                    maxWidth: "120px",
                    minWidth: "120px",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "15px",
                    border: "1px solid",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  {showingAndroid ? "Android" : "IOS"}
                  <ChevronDownIcon
                    aria-hidden="true"
                    style={{
                      maxWidth: "25px",
                      minWidth: "25px",
                    }}
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  style={{
                    border: "1px solid",
                    marginTop: "10px",
                    padding: "9px",
                    paddingTop: "2px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "10px",
                    }}
                  >
                    <Menu.Item
                      style={{
                        paddingBottom: "10px",
                      }}
                    >
                      {({ active }) => (
                        <a
                          onClick={() => {
                            setShowingAndroid(false);
                          }}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block w-full px-4 py-2 text-left text-sm"
                          )}
                        >
                          IOS
                        </a>
                      )}
                    </Menu.Item>
                    <form method="POST" action="#">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={() => {
                              setShowingAndroid(true);
                            }}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block w-full px-4 py-2 text-left text-sm"
                            )}
                          >
                            Android
                          </a>
                        )}
                      </Menu.Item>
                    </form>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </HeadingRow>
          {showingAndroid ? (
            <Text>
              <p>​</p>
              <p>
                1. On your Android device, go to your subscriptions in Google
                Play.
              </p>
              <p>2. Select "Dumb Ways to Sleep".</p>
              <p>3. Tap Cancel subscription.</p>
              <p>4. Follow the instructions.</p>
              <p></p>
            </Text>
          ) : (
            <Text>
              <p>​</p>
              <p>1. Open the Settings app.</p>
              <p>2. Tap your name.</p>
              <p>3. Tap Subscriptions.</p>
              <p>4. Tap "Dumb Ways to Sleep"</p>
              <p>
                5. Tap Cancel Subscription. You may need to scroll down to find
                the Cancel Subscription button.
              </p>
              <p></p>
            </Text>
          )}
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
