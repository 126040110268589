import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/tailwind.css";
import Modal from "react-modal";
{
  /* The following line can be included in your src/index.js or App.js file*/
}

import "bootstrap/dist/css/bootstrap.min.css";

Modal.setAppElement("#root");

ReactDOM.render(<App />, document.getElementById("root"));
