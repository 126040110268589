import { accessToken, refreshToken, removeCookie } from "./TokenService";

function isApiError(output) {
  return (
    typeof output === "object" &&
    output !== null &&
    typeof output.error === "string"
  );
}

const BASE_URL = "https://dwtd-sleep-testing.playsidestudios-devel.com";

interface ApiRequestType {
  Login: {
    email: string,
    password: string,
  };
  Register: {
    name: string,
    email: string,
    password: string,
  };
  ResetPassword: {
    code: string,
    password: string,
  };
}

interface ApiInfo {
  method: "GET" | "POST" | "PUT" | "DELETE";
  route: string;
  authenticated: boolean;
}

const Info = {
  Login: {
    method: "POST",
    route: "/v1/account/auth/login",
    authenticated: false,
  },
  Register: {
    method: "POST",
    route: "/v1/account/auth/signup",
    authenticated: false,
  },
  ResetPassword: {
    method: "POST",
    route: "/v1/account/auth/password-reset",
    authenticated: false,
  },
};

/** The actual generic request function */
export async function apiRequest(api) {
  try {
    const { method, route, authenticated } = Info[api.api];
    const headers = {
      "Content-Type": "application/json",
    };
    if (authenticated) {
      // headers.Authorization = `Bearer ${await getToken()}`;
    }
    let url = `${BASE_URL}${route}`;
    if (api.params) {
      url += `${api.params}`;
    }

    const res = await fetch(url, {
      method,
      headers,
      body: api.body ? JSON.stringify(api.body) : undefined,
    });
    const output = await res.json();
    if (res.status == 401 || res.status == 403) {
      removeCookie(accessToken);
      removeCookie(refreshToken);
    }
    if (isApiError(output)) {
      throw new TypeError(`Error: ${output.error}`);
    }
    if (res.status >= 400) {
      throw new TypeError(`Unknown Error ${res.status}: ${res.statusText}`);
    }
    return output;
  } catch (e) {
    return { error: e ? e.message : "" };
  }
}

export default { apiRequest };
