import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/login-illustration.svg";
import logo from "images/logo.jpg";
import appleLogo from "images/apple-icon.png";
import charatersImage from "../images/dwts-characters.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import { apiRequest } from "helpers/ApiRequest";
import { useHistory, useLocation } from "react-router-dom";
import cookieService, {
  accessToken,
  refreshToken,
  setCookie,
} from "helpers/TokenService";
import Alert from "react-bootstrap/Alert";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs mt-5 pt-5`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default (
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Reset Password",
  submitButtonText = "Reset Password",
  SubmitButtonIcon = LoginIcon
) => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const [passwordReset, setPasswordReset] = useState(false);

  let query = useQuery();

  const authCode = query.get("code");

  const handleResetPassword = async (event) => {
    event.preventDefault();
    if (newPassword.length <= 0 || confirmNewPassword.length <= 0) {
      setErrorMessage("Error: Please fill out both fields.");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("Error: Passwords do not match.");
      return;
    }

    const response = await apiRequest({
      api: "ResetPassword",
      body: { code: authCode, password: newPassword },
    });

    console.log(response);

    if (response.error) {
      setErrorMessage(response.error);
      setPasswordReset(false);
      return;
    } else {
      setPasswordReset(true);
    }
  };

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <MainContent>
              <Heading>{headingText}</Heading>

              {passwordReset === true && (
                <Alert key={"success"} variant={"success"}>
                  Password has been reset successfully.
                </Alert>
              )}
              {errorMessage.length > 0 && (
                <Alert key={"danger"} variant={"danger"}>
                  {errorMessage}
                </Alert>
              )}
              <Form>
                <Input
                  type="password"
                  placeholder="New Password"
                  value={newPassword}
                  onInput={(e) => setNewPassword(e.target.value)}
                />
                <Input
                  type="password"
                  placeholder="Confirm New Password"
                  value={confirmNewPassword}
                  onInput={(e) => setConfirmNewPassword(e.target.value)}
                />
                <SubmitButton type="submit" onClick={handleResetPassword}>
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
              </Form>
            </MainContent>
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
};
