import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Conditions/Terms of Use" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              Playside Studios also referred to as “us/we/our” in this Terms Of
              Use are providers and developers of products mobile applications
              under the brand name Playside Studios.
            </p>
            <p>
              This Agreement requires the use of arbitration on an individual
              basis to resolve disputes, rather than jury trials or class
              actions, and also limits the remedies available to you in the
              event of a dispute. This Terms of Service (“Agreement”) sets forth
              the legally binding terms for your use of any App (including any
              related services provided by Playside Studios). By using any App,
              you are accepting this Agreement and you represent and warrant
              that you have the right, authority, and capacity to enter into
              this Agreement. If you do not agree with all of the provisions of
              this Agreement, please do not use any App. This Agreement is the
              final, complete and exclusive agreement of you and Playside
              Studios with respect to the subject matters hereof (including the
              Apps) and supersedes and merges all prior discussions and
              agreements between the parties with respect to such subject
              matters (including any prior End User License Agreements, Terms of
              Service or Privacy Policy).
            </p>
            <p>​</p>
            <p>1. End User License Agreement</p>
            <p>​</p>
            <p>1.1 License</p>
            <p>
              Subject to the terms of this Agreement, Playside Studios grants
              you a non-transferable, non-exclusive, license to (a) use for your
              personal use, and (b) copy, for the purpose of downloading,
              installing and executing, the number of copies for which you are
              authorized by the download site of each App on a mobile device
              that you own or control for your use (the “License”).
            </p>
            <p>1.2 Certain Restrictions</p>
            <p>
              The rights granted to you in this Agreement are subject to the
              following restrictions: (a) you shall not license, sell, rent,
              lease, transfer, assign, distribute, host, or otherwise
              commercially exploit any App; (b) you shall not modify, translate,
              adapt, merge, make derivative works of, disassemble, decompile,
              reverse compile or reverse engineer any part of Apps, except to
              the extent the foregoing restrictions are expressly prohibited by
              applicable law; (c) you shall not access any App in order to build
              a similar or competitive service or application; (d) except as
              expressly stated herein, no part of any App may be copied,
              reproduced, distributed, republished, downloaded, displayed,
              posted or transmitted in any form or by any means, or (e) you
              shall not remove or destroy any copyright notices or other
              proprietary markings contained on or in any App. Any future
              release, update, or other addition to functionality of any App
              (including in-App purchases, additional levels, and gameplay
              enhancements) shall be subject to the terms of this Agreement,
              unless otherwise provided in terms associated with such addition.
              All copyright and other proprietary notices on any App content
              must be retained on any copies.
            </p>
            <p>1.3 Local Laws</p>
            <p>
              You are solely responsible for compliance with all applicable
              laws, including without limitation export and import regulations.
            </p>
            <p>1.4 Modification</p>
            <p>
              Playside Studios reserves the right, at any time, to modify,
              suspend, or discontinue the Apps or any part thereof with or
              without notice. You agree that Playside Studios will not be liable
              to you or to any third party for any modification, suspension, or
              discontinuance of any App or any part thereof.
            </p>
            <p>1.5 Ownership</p>
            <p>
              Apps provided to you are licensed to you and not sold. Playside
              Studios (and its licensors, where applicable) own all right, title
              and interest, including all related intellectual property rights,
              in and to all Apps, excluding your User Content (defined below).
              This Agreement is not a sale and does not convey to you any rights
              of ownership in or related to any App. The Playside Studios name,
              logo, and the product names associated with the Apps belong to
              Playside Studios (or its licensors, where applicable), and no
              right or license is granted to use them by implication, estoppel
              or otherwise. Playside Studios (and its licensors, where
              applicable) reserve all rights not granted in this Agreement.
            </p>
            <p>1.6 Advertisment</p>
            <p>
              When you start or stop an App, it may display an ad. During
              gameplay, banner and/or interstitial ads may be displayed.
            </p>
            <p>​</p>
            <p>2. User Content</p>
            <p>​</p>
            <p>2.1 User Content</p>
            <p>
              “User Content” of a user means any and all content that such user
              uploads, distributes, or otherwise provides via any App. You are
              solely responsible for your User Content. You assume all risks
              associated with use of your User Content, including any reliance
              on its accuracy, completeness or usefulness by others, or any
              disclosure of your User Content that makes you or any third party
              personally identifiable. You hereby represent and warrant that
              your User Content does not violate the Acceptable Use Policy
              (defined below). You may not state or imply that your User Content
              is in any way provided, sponsored or endorsed by Playside Studios.
              Playside Studios is not obligated to backup any User Content and
              User Content may be deleted at any time. You are solely
              responsible for creating backup copies of your User Content if you
              desire.
            </p>
            <p>2.2 License</p>
            <p>
              By uploading, distributing, or otherwise using your User Content
              with any App, you automatically grant, and you represent and
              warrant that you have the right to grant, to Playside Studios an
              irrevocable, non-exclusive, royalty-free and fully paid, worldwide
              license, with the right to grant sublicenses, to reproduce,
              distribute, publicly display, publicly perform, prepare derivative
              works of, incorporate into other works, and otherwise use your
              User Content, solely to display your User Content on any App.
            </p>
            <p>2.3 Feedback</p>
            <p>
              If you provide Playside Studios any feedback or suggestions
              (“Feedback”), you hereby assign to Playside Studios all rights in
              the Feedback and agree that Playside Studios shall have the right
              to use such Feedback and related information in any manner it
              deems appropriate. Playside Studios will treat any Feedback you
              provide to Playside Studios as non-confidential and
              non-proprietary. You agree that you will not submit to Playside
              Studios any information or ideas that you consider to be
              confidential or proprietary.
            </p>
            <p>2.4 Acceptable Use Policy</p>
            <p>
              The following sets forth Playside Studios’s - “Acceptable Use
              Policy”:
            </p>
            <p>
              2.4.1 You agree not to use any App to upload, distribute, or
              otherwise use any User Content (a) that violates any third-party
              right, including any copyright, trademark, patent, trade secret,
              moral right, privacy right, right of publicity, or any other
              intellectual property or proprietary right; (b) that is tortious,
              trade libelous, defamatory, false, or intentionally misleading,
              (c) that is harassing, abusive, threatening, harmful, vulgar,
              obscene, or offensive, or that contains pornography, nudity, or
              graphic or gratuitous violence, or that promotes violence, racism,
              discrimination, bigotry, hatred, or physical harm of any kind
              against any group or individual, or is otherwise objectionable,
              (d) that is harmful to minors in any way; (e) that constitutes
              unsolicited or unauthorized advertising, promotional materials,
              junk mail, spam, chain letters, pyramid schemes, or any other form
              of duplicative or unsolicited messages, whether commercial or
              otherwise; or (f) that violates of any law, regulation, or
              contractual obligations.
            </p>
            <p>
              2.4.2 You agree not to use any App to: (a) upload or distribute
              any computer viruses, worms, malicious code, or any software
              intended to damage or alter a computer system or data; (b) collect
              information or data regarding other users, including e-mail
              addresses, without their consent (e.g., using harvesting bots,
              robots, spiders, or scrapers); (c) disable, overly burden, impair,
              or otherwise interfere with servers or networks connected to Apps
              (e.g., a denial of service attack); (d) attempt to gain
              unauthorized access to the Site or App or servers or networks
              connected to Apps (e.g., through password mining); or (e)
              interfere with another user’s use and enjoyment of any App.
            </p>
            <p>2.5 Enforcement</p>
            <p>
              We reserve the right (but have no obligation) to review any User
              Content in our sole discretion. We may remove or modify your User
              Content at any time for any reason in our sole discretion with or
              without notice to you.
            </p>
            <p>​</p>
            <p>3. Term and Termination</p>
            <p>​</p>
            <p>
              3.1 This Agreement commences on the date you accept this Agreement
              (as described in the preamble) and will remain in full force and
              effect while you use the App, unless earlier terminated in
              accordance with this Agreement.
            </p>
            <p>
              3.2 Notwithstanding the foregoing, if you used any App prior to
              the date you accepted this Agreement (as described in the
              preamble), you hereby acknowledge and agree that this Agreement
              commences on the date you first use any App (which ever is earlier
              and which may be prior to the Agreement Version Date) and will
              remain in full force and effect while you use any App, unless
              earlier terminated in accordance with this Agreement.
            </p>
            <p>
              3.3 We may (a) suspend your rights to use any App, and/or any
              related services or (b) terminate this Agreement, at any time for
              any reason at our sole discretion with or without notice to you,
              including if we in good faith believe you have violated the
              Acceptable Use Policy or any other provision of this Agreement.
              Without limiting the foregoing, Playside Studios reserves the
              right to terminate its Agreement with any user who repeatedly
              infringes third party copyright rights upon prompt notification to
              Playside Studios by the copyright owner or the copyright owner’s
              legal agent.
            </p>
            <p>
              3.4 Upon termination of this Agreement, your right to use the App
              will automatically terminate immediately. You understand that any
              termination may involve deletion of your User Content associated
              therewith from our live databases. Playside Studios will not have
              any liability whatsoever to you for any termination of this
              Agreement, including deletion of your User Content. Even after
              this Agreement is terminated, the following provisions of this
              Agreement will remain in effect: Sections 1.2, 1.3, 1.4, 1.5, 2,
              3.4, 4, 5, 6, 7, 8, 9, and 10.
            </p>
            <p>​</p>
            <p>4. Indemnity</p>
            <p>​</p>
            <p>
              You agree to defend, indemnify and hold harmless Playside Studios
              (and its suppliers) from and against any claims, suits, losses,
              damages, liabilities, costs, and expenses (including reasonable
              attorneys’ fees) brought by third parties resulting from or
              relating to: (i) your use of any App, (ii) your User Content, or
              (iii) your violation of this Agreement. Playside Studios reserves
              the right, at your expense, to assume the exclusive defense and
              control of any matter for which you are required to indemnify
              Playside Studios and you agree to cooperate with our defense of
              these claims. You agree not to settle any matter without the prior
              written consent of Playside Studios. Playside Studios will use
              reasonable efforts to notify you of any such claim, action or
              proceeding upon becoming aware of it.
            </p>
            <p>​</p>
            <p>5. Third Parties</p>
            <p>​</p>
            <p>5.1 Application Stores</p>
            <p>
              You acknowledge and agree that the availability of the App is
              dependent on the third party from which you received App, e.g.,
              the Apple iPhone or Android stores (“Application Store”). You
              acknowledge that this Agreement is between you and Playside
              Studios and not with the Application Store. The Application Store
              is not responsible for the App, the content thereof, maintenance,
              support services, and warranty therefor, and addressing any claims
              relating thereto (e.g., product liability, legal compliance, or
              intellectual property infringement). You agree to pay all fees
              charged by the Application Store in connection with App (if any).
              You agree to comply with, and your license to use App is
              conditioned upon your compliance with, all applicable third party
              terms of agreement (e.g., the Application Store’s terms and
              policies) when using App. You acknowledge that the Application
              Store (and its subsidiaries) are third party beneficiaries of this
              Agreement and will have the right to enforce this Agreement.
            </p>
            <p>5.2 Third Party Services</p>
            <p>
              Playside Studios may permit certain third party applications (like
              leaderboards, game networks, ad networks, third-party analytic
              software etc.) to take and (or) provide content or any end user’s
              data through the App (“Third Party Services”). The App may be used
              to send content provided by the Third Party Service between users
              who have the Third Party Service installed on their device. When
              you do so, Playside Studios will share information with the Third
              Party Service as described in the Playside Studios Privacy Policy.
              Playside Studios is not responsible for and does not control Third
              Party Services. Playside Studios provides these Third Party
              Services only as a convenience to you. Playside Studios has no
              obligation to review or monitor, and does not approve, endorse, or
              make any representations or warranties with respect to Third Party
              Services. When you access a Third Party Service, the applicable
              third party’s terms and policies apply, including the third
              party’s privacy policies. You should make whatever investigation
              you feel necessary or appropriate before proceeding with any
              transaction in connection with any Third Party Services.
            </p>
            <p>5.3 Other Users</p>
            <p>
              An App may contain User Content provided by other users of the
              App. Playside Studios is not responsible for and does not control
              User Content. Playside Studios has no obligation to review or
              monitor, and does not approve, endorse, or make any
              representations or warranties with respect to User Content. You
              use all User Content and interact with other users at your own
              risk. Your interactions with other users are solely between you
              and the other user and we are under no obligation to become
              involved. You agree that Playside Studios will not be responsible
              for any liability incurred as the result of any such interactions.
            </p>
            <p>5.4 Release</p>
            <p>
              You hereby irrevocably and unconditionally release and forever
              discharge Playside Studios (and its suppliers) from any and all
              claims, demands, and rights of action, whether now known or
              unknown, which relates to any interactions with, or act or
              omission of, any Third Party Service, other Apps users, or Third
              Party Advertisers. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY
              WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE
              FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO
              CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS
              OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN
              BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT
              WITH THE DEBTOR.”
            </p>
            <p>​</p>
            <p>6. Disclaimers</p>
            <p>​</p>
            <p>
              6.1 APPS ARE PROVIDED “AS-IS” AND AS AVAILABLE AND PLAYSIDE
              STUDIOS (AND ITS SUPPLIERS) EXPRESSLY DISCLAIM ANY WARRANTIES AND
              CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE
              WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
              NON-INFRINGEMENT. PLAYSIDE STUDIOS (AND ITS SUPPLIERS) MAKE NO
              WARRANTY THAT ANY APP: (A) WILL MEET YOUR REQUIREMENTS; (B) WILL
              BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
              BASIS; OR (C) WILL BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR
              SAFE.
            </p>
            <p>
              6.2 SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
              WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
            </p>
            <p>​</p>
            <p>7. Limitation on Liability</p>
            <p>​</p>
            <p>
              7.1 IN NO EVENT SHALL PLAYSIDE STUDIOS (OR ITS SUPPLIERS) BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY
              INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
              PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT OR
              PLAYSIDE STUDIOS’ PRIVACY PRACTICES, ANY APP, EVEN IF PLAYSIDE
              STUDIOS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              ACCESS TO, AND USE OF, ANY APP ARE AT YOUR OWN DISCRETION AND
              RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
              COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA RESULTING
              THEREFROM. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
              HEREIN, PLAYSIDE STUDIOS’ LIABILITY TO YOU FOR ANY DAMAGES ARISING
              FROM OR RELATED TO THIS AGREEMENT OR PLAYSIDE STUDIOS’ PRIVACY
              PRACTICES (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF
              THE ACTION), WILL AT ALL TIMES BE LIMITED TO THE AMOUNT YOU’VE
              PAID PLAYSIDE STUDIOS IN THE PRIOR 12 MONTHS (IF ANY). IN NO EVENT
              SHALL PLAYSIDE STUDIOS’ SUPPLIERS HAVE ANY LIABILITY ARISING OUT
              OF OR IN ANY WAY CONNECTED TO THIS AGREEMENT.
            </p>
            <p>
              7.2 SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
              LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE
              LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU AND YOU MAY ALSO HAVE
              OTHER LEGAL RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.
            </p>
            <p>​</p>
            <p>8. Fees</p>
            <p>​</p>
            <p>
              You agree to pay all fees and applicable taxes incurred by you or
              anyone making purchases through the App. All payments will be
              processed through the Application Stores from which you originally
              downloaded the App.
            </p>
            <p>
              Some parts of the Service are billed on a subscription basis
              (“Subscription(s)”). Subscription provides access to dynamic
              content or services from within the App on an ongoing basis.
              Subscriptions may be available at different fees chargeable for a
              set period
            </p>
            <p>
              specified in the App (“Subscription Period”). Payment will be
              charged to Application Store account at confirmation of purchase
              in accordance with the relevant Application Store rules.
            </p>
            <p>
              Subscription automatically renews unless auto-renew is turned off
              or cancelled by You at least 24-hours before the end of the
              current Subscription Period. Your Application Store Account will
              be charged for renewal within 24-hours prior to the end of the
              current period, and identify the cost of the renewal in accordance
              with the relevant Application Store rules. Subscriptions may be
              managed by You according to Your Application Store account
              Settings after purchase. No cancellation of the current
              Subscription is allowed during active Subscription Period.
            </p>
            <p>
              Trial period may be provided with respect to certain Subscription.
              You may cancel a subscription during its free trial period (if
              available) via the Subscription setting through Your Application
              Store account. This must be done 24 hours before the end of the
              subscription period to avoid being charged.
            </p>
            <p>
              Playside Studios may revise the pricing for the goods and services
              offered to you through the App at any time. YOU ACKNOWLEDGE THAT
              Playside Studios IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY
              REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION
              FOR UNUSED VIRTUAL ITEMS WHEN YOU CLOSE YOUR ACCOUNT OR WHEN YOU
              STOP TO USE THE APP.
            </p>
            <p>​</p>
            <p>9. General</p>
            <p>​</p>
            <p>9.1 Changes to this Agreement</p>
            <p>
              This Agreement is subject to occasional revision, and if we make
              any substantial changes, we may notify you by prominently posting
              notice of the changes on our Site. Any changes to this Agreement
              will be effective upon the earlier of thirty (30) calendar days
              following our dispatch of an e-mail notice to you (if applicable)
              or thirty (30) calendar days following our posting of notice of
              the changes on our Site or (and) Social Medias. These changes will
              be effective immediately for new users of our Apps. Continued use
              of our Apps following notice of such changes shall indicate your
              acknowledgement of such changes and agreement to be bound by the
              terms and conditions of such changes. The date on which the latest
              update was made is indicated at the top of this document. We
              recommend that you print a copy of this Agreement for your
              reference and revisit this webpage from time to time to ensure you
              are aware of any changes.
            </p>
            <p>9.2 Notice</p>
            <p>
              Any notice provided to Playside Studios pursuant to this Agreement
              should be sent to: support@playsidestudios.com
            </p>
            <p>9.3 Severability</p>
            <p>
              If any provision of this Agreement is, for any reason, held to be
              invalid or unenforceable, the other provisions of this Agreement
              will be unimpaired and the invalid or unenforceable provision will
              be deemed modified so that it is valid and enforceable to the
              maximum extent permitted by law.
            </p>
            <p>9.4 Entire Agreement</p>
            <p>
              This Agreement is the final, complete and exclusive agreement of
              you and Playside Studios with respect to the subject matters
              hereof (including all Apps) and supersede and merge all prior
              discussions and agreements between the parties with respect to
              such subject matters (including any prior End User License
              Agreements and Terms of Service or Privacy Policy). Our failure to
              exercise or enforce any right or provision of this Agreement shall
              not operate as a waiver of such right or provision. The section
              titles in this Agreement are for convenience only and have no
              legal or contractual effect. The word including means including
              without limitation. Your relationship to Playside Studios is that
              of an independent contractor, and neither party is an agent or
              partner of the other. This Agreement, and your rights and
              obligations herein, may not be assigned by you without Playside
              Studios’ prior written consent, and any attempted assignment in
              violation of the foregoing will be null and void. Playside Studios
              may assign this Agreement in connection with a merger,
              acquisition, reorganization or sale of all or substantially all of
              its assets, or other operation of law, without your consent. The
              terms of this Agreement shall be binding upon assignees.
            </p>
            <p>​</p>
            <p>10. Apple Application Store Additional Terms and Conditions</p>
            <p>​</p>
            <p>
              The following additional terms and conditions apply to you if you
              are using an App from the Apple Application Store. To the extent
              the other terms and conditions of this Agreement are less
              restrictive than, or otherwise conflict with, the terms and
              conditions of this Section 10, the more restrictive or conflicting
              terms and conditions in this Section 10 apply, but solely with
              respect to Apps from the Apple Application Store.
            </p>
            <p>10.1 Acknowledgement</p>
            <p>
              Playside Studios and you acknowledge that this Agreement is
              concluded between Playside Studios and you only, and not with
              Apple, and Playside Studios, not Apple, is solely responsible for
              App and the content thereof. To the extent this Agreement provides
              for usage rules for App that are less restrictive than the Usage
              Rules set forth for App in, or otherwise is in conflict with, the
              Application Store Terms of Service, the more restrictive or
              conflicting Apple term applies.
            </p>
            <p>10.2 Scope of License</p>
            <p>
              The license granted to you for App is limited to a
              non-transferable license to use App on an iOS Product that you own
              or control and as permitted by the Usage Rules set forth in the
              Application Store Terms of Service.
            </p>
            <p>10.3 Maintenance and Support</p>
            <p>
              Playside Studios is solely responsible for providing any
              maintenance and support services with respect to App, as specified
              in this Agreement (if any), or as required under applicable law.
              Playside Studios and you acknowledge that Apple has no obligation
              whatsoever to furnish any maintenance and support services with
              respect to App.
            </p>
            <p>10.4 Warranty</p>
            <p>
              Playside Studios is solely responsible for any product warranties,
              whether express or implied by law, to the extent not effectively
              disclaimed. In the event of any failure of App to conform to any
              applicable warranty, you may notify Apple, and Apple may refund
              the purchase price for App to you; and to the maximum extent
              permitted by applicable law, Apple will have no other warranty
              obligation whatsoever with respect to App, and any other claims,
              losses, liabilities, damages, costs or expenses attributable to
              any failure to conform to any warranty will be Playside Studios’
              sole responsibility.
            </p>
            <p>10.5 Product Claims</p>
            <p>
              Playside Studios and you acknowledge that Playside Studios, not
              Apple, is responsible for addressing any claims of you or any
              third party relating to App or your possession and/or use of App,
              including, but not limited to: (i) product liability claims; (ii)
              any claim that App fails to conform to any applicable legal or
              regulatory requirement; and (iii) claims arising under consumer
              protection or similar legislation. This Agreement does not limit
              Playside Studios’ liability to you beyond what is permitted by
              applicable law.
            </p>
            <p>10.6 Intellectual Property Rights</p>
            <p>
              Playside Studios and you acknowledge that, in the event of any
              third party claim that App or your possession and use of App
              infringes that third party’s intellectual property rights,
              Playside Studios, not Apple, will be solely responsible for the
              investigation, defense, settlement and discharge of any such
              intellectual property infringement claim.
            </p>
            <p>10.7 Legal Compliance</p>
            <p>
              You represent and warrant that (i) you are not located in a
              country that is subject to a U.S. Government embargo, or that has
              been designated by the U.S. Government as a “terrorist supporting”
              country; and (ii) you are not listed on any U.S. Government list
              of prohibited or restricted parties.
            </p>
            <p>10.8 Developer Name and Address</p>
            <p>
              Playside Studios’ contact information for any end-user questions,
              complaints or claims with respect to App is set forth in Section
              9.2.
            </p>
            <p>10.9 Third Party Terms of Agreement</p>
            <p>
              You must comply with applicable third party terms of agreement
              when using App.
            </p>
            <p>10.10 Third Party Beneficiary</p>
            <p>
              Playside Studios and you acknowledge and agree that Apple, and
              Apple’s subsidiaries, are third party beneficiaries of this
              Agreement, and that, upon your acceptance of the terms and
              conditions of this Agreement, Apple will have the right (and will
              be deemed to have accepted the right) to enforce this Agreement
              against you as a third party beneficiary thereof.
            </p>
            <p></p>
            <p>1. GENERAL INFORMATION</p>
            <p> </p>
            <p>1.1 Dumb Ways to Sleep PRODUCTS</p>
            <p>
              These Terms & Conditions (these “Terms”) contain the terms and
              conditions on which we supply content, products or services listed
              on www.dumbwaystosleep.com (the “Website”), through our
              applications (the “Apps”) or via other delivery methods to you
              (the Website and such content, products, services and the Apps are
              collectively referred to herein as the “Product” or “Products”,
              which may be updated from time-to-time at the sole discretion of
              Dumb Ways to Sleep). Please read these terms and conditions,
              carefully before ordering any Products from the Website or third
              party App stores (e.g. the Apple App Store, the Android Play
              Store, Amazon, etc.). The terms “Dumb Ways to Sleep,” “us” or “we”
              refers to Applantis, Inc. and its wholly owned AUS based
              subsidiary, Applantis PTY LTD. The term “Device” refers to the
              device which is used to access the Products including but not
              limited to computers, smart phones and tablets. The term “you”
              refers to the user of the Products. When you order (“Order”) any
              Products, or otherwise use or access the Products, you agree to be
              bound by these Terms and all applicable laws, rules and
              regulations. You may also be asked to click “I accept” at the
              appropriate place prior to your purchase of access to the
              Products. At such time, if you do not click “I accept”, you may
              not be able to complete such purchase or gain such access. By
              using the Products, you indicate that you accept these Terms and
              that you agree to abide by them. If you do not agree to these
              Terms, please refrain from using the Products.
            </p>
            <p> </p>
            <p>
              Our contact email address is support@dumbwaystosleep.com All
              correspondence to Dumb Ways to Sleep including any queries you may
              have regarding your use of the Products or these Terms should be
              sent to this contact email address.
            </p>
            <p> </p>
            <p>1.2 ARBITRATION NOTICE AND CLASS ACTION WAIVER</p>
            <p> </p>
            <p>
              PLEASE NOTE THAT THESE TERMS CONTAIN AN ARBITRATION CLAUSE. EXCEPT
              FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THE ARBITRATION CLAUSE,
              YOU AND DUMB WAYS TO SLEEP AGREE THAT DISPUTES RELATING TO THESE
              TERMS OR YOUR USE OF THE PRODUCTS WILL BE RESOLVED BY MANDATORY
              BINDING ARBITRATION, AND YOU AND DUMB WAYS TO SLEEP. WAIVE ANY
              RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE
              ARBITRATION.
            </p>
            <p> </p>
            <p>1.3 BASIS OF LICENSE</p>
            <p>
              (a) These Terms and the Order set out the whole agreement between
              you and us for the supply of the Products. In order to participate
              in certain Products, you may be required to agree to additional
              terms and conditions; those additional terms are hereby
              incorporated into these Terms. Where such terms are inconsistent
              with these Terms, the additional terms shall control.
            </p>
            <p> </p>
            <p>
              (b) Please check that the details in these Terms and on the Order
              are complete and accurate before you use or commit yourself to
              purchase the Products. If you think that there is a mistake,
              please make sure that you ask us to confirm any changes in
              writing, as we only accept responsibility for statements and
              representations made in writing by an officer of Dumb Ways to
              Sleep.
            </p>
            <p> </p>
            <p>
              (c) AS PART OF YOUR USE OF THE PRODUCTS, YOU AFFIRMATIVELY CONSENT
              TO THE PROCESSING AND STORAGE OF YOUR PERSONAL INFORMATION IN
              AUSTRALIA, INCLUDING THE PROCESSING AND STORING OF YOUR PERSONAL
              INFORMATION IN AUSTRALIA FOR THE PURPOSES OF PROCESSING PAYMENTS
              AND TRACKING INDIVIDUAL USE OF THE PRODUCTS. BY USING THE
              PRODUCTS, YOU ACKNOWLEDGE THAT YOU UNDERSTAND AND AGREE THAT
              AUSTRALIA MAY NOT HAVE THE SAME LEVEL OF PROTECTIONS FOR YOUR
              PERSONAL INFORMATION THAT EXIST IN YOUR COUNTRY OF RESIDENCE, AND
              YOU NONETHELESS CONSENT TO THE PROCESSING AND STORAGE OF YOUR
              PERSONAL INFORMATION IN AUSTRALIA. WE WILL TAKE MEASURES AS
              REQUIRED TO COMPLY WITH APPLICABLE LAW REGARDING THE TRANSFER,
              STORAGE AND USE OF CERTAIN PERSONAL INFORMATION.
            </p>
            <p> </p>
            <p>1.4 CHANGES TO TERMS</p>
            <p>
              Dumb Ways to Sleep reserves the right to change or update these
              Terms, or any other of our policies or practices, at any time, and
              will notify users by posting such changed or updated Terms on this
              page. Any changes or updates will be effective immediately upon
              posting to support@dumbwaystosleep.com. Your continued use of the
              Products constitutes your agreement to abide by the Terms as
              changed. Under certain circumstances we may also elect to notify
              you of changes or updates to our Terms by additional means, such
              as pop-up or push notifications within the Products or email.
            </p>
            <p> </p>
            <p>2. MEMBERSHIPS AND SUBSCRIPTIONS</p>
            <p> </p>
            <p>2.1 BECOMING A MEMBER</p>
            <p>
              (a) You may sign up as a registered user of the Products free of
              charge (a “Member”). To become a Member you need to go to the
              relevant section of the Products, then submit your email address
              to us, and create a password to be used in conjunction with that
              email address. You are responsible for maintaining the
              confidentiality of your account and password and for restricting
              access to your Device.
            </p>
            <p> </p>
            <p>
              (b) In the course of your use of the Products, you may be asked to
              provide certain personalised information to us (such information
              is referred to hereinafter as “User Information”). This User
              Information may include information from your Facebook and similar
              social networking profiles. Our information collection and use
              policies with respect to the privacy of such User Information are
              set forth in the Dumb Ways to Sleep Privacy Policy. You
              acknowledge and agree that you are solely responsible for the
              accuracy and content of User Information, and you agree to keep it
              up to date.
            </p>
            <p> </p>
            <p>
              (c) By placing an Order through the Products, you warrant that:
            </p>
            <p> </p>
            <p>
              (i) You are legally capable of entering into binding contracts;
              (ii) All registration information you submit is truthful and
              accurate; (iii) You will maintain the accuracy of such
              information; and (iv) Your use of the Products does not violate
              any applicable law or regulation.
            </p>
            <p> </p>
            <p>2.2 ONCE A MEMBER</p>
            <p>
              You are responsible for maintaining the confidentiality of your
              account, password and other User Information and for restricting
              access to your Device to further help protect such information.
              You are responsible for updating your User Information.
            </p>
            <p> </p>
            <p>2.3 USE OF DUMB WAYS TO SLEEP BY MINORS</p>
            <p>
              You must be 18 years of age, or the age of majority in your
              province, territory or country, to sign up as a registered user of
              the Products. Individuals under the age of 18, or the applicable
              age of majority, may utilise the Products only with the
              involvement and consent of a parent or legal guardian, under such
              person's account and otherwise subject to these Terms.
            </p>
            <p> </p>
            <p>2.4 MEMBERSHIP</p>
            <p>
              As a Dumb Ways to SleepMember you will receive access to certain
              sections, features and functions of the Products that are not
              available to non-members.
            </p>
            <p> </p>
            <p>
              By agreeing to become a Member you opt-in to receiving occasional
              special offer, marketing, survey and Product based communication
              emails. You can easily unsubscribe from Dumb Ways to Sleep
              commercial emails by following the opt-out instruction in these
              emails. Dumb Ways to Sleep memberships and subscriptions are not
              transferable and therefore cannot be sold or exchanged or
              transferred in any way whatsoever.
            </p>
            <p> </p>
            <p>2.5 SUBSCRIPTIONS</p>
            <p>
              (a) Dumb Ways to Sleep account holders may access the Products in
              two ways:
            </p>
            <p> </p>
            <p>
              (i) "Basics" Free Trial: a free-of-charge program, which gives
              unlimited access to ten days of our “Foundation Course.”
            </p>
            <p> </p>
            <p>
              (ii) Paid Subscription: a subscription fee-based program, which
              gives access to all content including and beyond the "Basics" Free
              Trial. You will only have access to the Subscription Program while
              your subscription is active and subsisting. You may have access to
              a free trial period of the Subscription Program in accordance with
              certain promotional offers. All subscription services provide
              access through the Products. You can become a subscriber to the
              Subscription Program by purchasing a subscription to the Products
              from the Website, within the Apps, where allowed by the App
              marketplace partners, or through a bundle with one or more of our
              bundle subscription partners. Please note that if you purchase a
              subscription through the Apple iTunes Store or our iPhone
              application, the sale is final, and we will not provide a refund.
              Your purchase will be subject to Apple’s applicable payment
              policy, which also may not provide for refunds. If you purchase a
              subscription through the Google Play store, the sale is final and
              we will not provide a refund. Your purchase will be subject to
              Google’s applicable payment policy, which also may not provide for
              refunds. If you purchase through one or more of our bundle
              subscription partners, the purchase may be further subject to the
              Terms and Conditions of such partners, and payment and management
              of the bundle subscription may be administered by them.
            </p>
            <p> </p>
            <p>
              (b) Dumb Ways to Sleep offers monthly, tri monthly and annual
              subscription options. For the purposes of our monthly and yearly
              subscriptions, a month constitutes 30 calendar days and a year
              constitutes 365 calendar days. For the purposes of our forever
              subscription, forever constitutes 100 years or until the date Dumb
              Ways to Sleep ceases to commercially offer the Products.
            </p>
            <p> </p>
            <p>
              (c) Our “Monthly” subscription is paid in monthly instalments. For
              each month that your monthly subscription is active, you
              acknowledge and agree that Dumb Ways to Sleep is authorised to
              charge the same credit card as was used for the initial
              subscription fee or other payment method as set forth in section
              2.5(h) (the “Payment Method”) in the amount of the current monthly
              subscription fee as of the time of renewal. The monthly renewal
              subscription fees will continue to be billed to the Payment Method
              you provided, automatically until cancelled. You must cancel your
              subscription before it renews each month in order to avoid billing
              of the next month’s subscription fee to the Payment Method you
              provided. Refunds cannot be claimed for any partial-month
              subscription period.
            </p>
            <p> </p>
            <p>
              (d) Our “Yearly” subscription is paid for by an upfront one-off
              payment with automatic annual renewals. You acknowledge and agree
              that Dumb Ways to Sleep is authorised to charge the Payment Method
              used for (i ) the initial annual subscription fee at the rate
              secured at the time of purchase, and (ii) the renewal subscription
              fee(s) at the non-discounted rate in effect at the time of any
              such renewal. You must cancel your subscription before it renews
              in order to avoid billing of the renewal subscription fee to the
              Payment Method you provided. Refunds cannot be claimed for any
              partial subscription period.
            </p>
            <p> </p>
            <p>
              (e) Our “Forever” subscription is paid for by a one-off upfront
              payment.
            </p>
            <p> </p>
            <p>(f) Subscription</p>
            <p>
              (a) Dumb Ways to Sleep offers certain special discount pricing
              options (the “Special Discount Pricing Options”). The Special
              Discount Pricing Options will permit users to access to the same
              content included in the Paid Subscription; such Special Discount
              Pricing Options shall only be available to qualified users (the
              “Qualified Users”). To be considered a Qualified User, your
              information will be provided directly Dumb Ways to Sleep
              third-party verification system. Dumb Ways to Sleep reserves the
              right to determine if you are a Qualifying User in our sole
              discretion.
            </p>
            <p> </p>
            <p>
              (g) You agree to promptly notify Dumb Ways to Sleep of any changes
              to the Payment Method you provided while any subscriptions remain
              outstanding. You are responsible for all applicable fees and
              charges incurred, including applicable taxes, and all
              subscriptions purchased by you.
            </p>
            <p> </p>
            <p>
              (h) In the course of your use of the Products, Dumb Ways to Sleep
              and its third party payment service provider may receive and
              implement updated credit card information from your credit card
              issuer in order to prevent your subscription from being
              interrupted by an outdated or invalid card. This disbursement of
              the updated credit card information is provided to Dumb Ways to
              Sleep and Dumb Ways to Sleep third party payment service provider
              at the sole election of your credit card issuer. Your credit card
              issuer may give you the right to opt-out of the update service.
              Should you desire to do so, please contact your credit card
              issuer.
            </p>
            <p> </p>
            <p>
              (i) Our obligation to provide the Products only comes into being
              when we take receipt of your Order, and we confirm your purchase
              to you by email. We shall confirm your Order and send you an email
              to confirm your access to the subscription purchased. Please quote
              the Order number in all subsequent correspondence with us. Prices
              in US Dollars and Euros include local taxes. All prices in Pound
              Sterling include VAT unless otherwise stated. You agree not to
              hold us responsible for banking charges incurred due to payments
              on your account. If payment is not received by us from the Payment
              Method you provided, you agree to pay all amounts due upon demand
              by us. You agree that you are not permitted to resell any Products
              purchased through Dumb Ways to Sleep for commercial purposes.
            </p>
            <p> </p>
            <p>2.6 DEVICE REQUIREMENTS</p>
            <p>
              To enjoy Dumb Ways to Sleep via your smartphone or other Device,
              your Device must satisfy certain system requirements. These
              requirements can be found on the Website and the Google and Apple
              marketplaces.
            </p>
            <p> </p>
            <p>2.7 GIFTING</p>
            <p>
              “Gift Subscriptions” are pre-paid memberships to the Products. A
              person who purchases the gift is referred to in these terms as the
              “Giftor”. A person who receives and redeems a Gift Subscription to
              the Products is referred to in these terms as the “Recipient”.
              Gift subscriptions are paid for as a one-off upfront payment. Once
              bought, the Giftor will receive an Order confirmation and receipt.
              The Dumb Ways to Sleep gift subscription will be sent to the
              Recipient on the Giftor’s specified date. Gifting codes can only
              be used once in the country for which they were purchased and
              cannot be redeemed for cash, resold or combined with any other
              offers, including free trial. Please note that gifting codes
              cannot be redeemed if the Recipient has already purchased a
              subscription through the Apple iTunes Store or our iPhone
              application, or the Google Play Store or our Android application.
              We will automatically bill the Payment Method you provided for any
              purchased Gift Subscriptions at the time of purchase, not
              delivery. There are no refunds or other credits for Gift
              Subscription that are not redeemed. Dumb Ways to Sleep will notify
              the Recipient prior to the end of the Gift Subscription that the
              gift period is about to expire. Dumb Ways to Sleep is not
              responsible if a Gift Subscription is lost, stolen or used without
              permission.
            </p>
            <p>​</p>
            <p>2.8 CORPORATE AND OTHER CONSUMER COMMUNITIES</p>
            <p>
              While Dumb Ways to Sleep is a consumer products company, there is
              increasing interest by large consumer communities (corporations,
              universities, hospitals, etc.) (“Communities”) to introduce the
              Products to their employees and members. In some cases, these
              Communities may supplement these Terms with their own terms and
              conditions. In such event, these Community terms and conditions
              shall also apply to your use of the Products. In the event of any
              conflict with such additional terms and these Terms, these Terms
              shall prevail.
            </p>
            <p>2.9 CHANGING FEES AND CHARGES</p>
            <p>
              We reserve the right to change our subscription plans or adjust
              pricing for our service or any components thereof in any manner
              and at any time as we may determine in our sole and absolute
              discretion. Except as otherwise expressly provided for in these
              Terms, any price changes or changes to your subscription plan will
              take effect following notice to you.
            </p>
            <p> </p>
            <p>3. CANCELLATION OF SERVICES</p>
            <p> </p>
            <p>3.1 CANCELLATION BY YOU</p>
            <p>
              (a) You may cancel a subscription at any time. Cancellation is
              effective at the end of the applicable monthly period.
            </p>
            <p> </p>
            <p>
              (b) Please note that if you purchase a subscription through the
              Apple iTunes Store or our iPhone application, you may cancel your
              subscription by cancelling automatic renewal of paid In App
              Subscriptions by selecting Manage App Subscriptions in your iTunes
              Account settings and selecting the subscription you want to
              modify. If you purchase a subscription through the Google Play
              store you may cancel automatic renewals in account settings under
              Subscriptions in the Google Play app, or according to the current
              process outlined by Google Play.
            </p>
            <p> </p>
            <p>3.2 CANCELLATION BY US</p>
            <p>
              We may suspend or terminate your use of the Products as a result
              of your fraud or breach of any obligation under these Terms. Such
              termination or suspension may be immediate and without notice. A
              breach of these Terms, includes without limitation, the
              unauthorised copying or download of our audio or video content
              from the Products.
            </p>
            <p> </p>
            <p>3.3 PROMOTION AND DISCOUNT CODES</p>
            <p>
              Any promotion code or offer (including the Special Discount
              Pricing Options) provided by us may not be used in conjunction
              with any other promotion code or offer, past or present.
              Introductory offers are only available to new users of the
              Products, except where expressly stated otherwise. Previous users
              or trial users of the Products do not qualify as new users. No
              promotion code or discount will apply to corporate or other
              Community subscriptions. Unless otherwise set forth in the terms
              of any promotion, all pricing promotions or discounts will apply
              to the initial period of the subscription, and any renewals will
              be charged at the rate in effect at the time of renewal for the
              type of subscription purchased.
            </p>
            <p> </p>
            <p>4. PROHIBITED USE OF THE PRODUCTS</p>
            <p> </p>
            <p>
              4.1 You agree not to upload, post, email or otherwise send or
              transmit or introduce any material that contains software viruses
              or any other computer code, files or programs designed to
              interrupt, harm, damage, destroy or limit the functionality of any
              computer software or hardware or equipment linked directly or
              indirectly with the Products or the Products themselves. You agree
              not to interfere with the servers or networks underlying or
              connected to the Products or to violate any of the procedures,
              policies or regulations of networks connected to the Products. You
              may not access the Products in an unauthorised manner.
            </p>
            <p> </p>
            <p>
              4.2 You agree not to impersonate any other person while using the
              Products, conduct yourself in an offensive manner while using the
              Products, or use the Products for any illegal, immoral or harmful
              purpose.
            </p>
            <p> </p>
            <p>
              4.3 By breaching the provisions of this section 4, you may commit
              a criminal offence under applicable laws. We may report any such
              breach to the relevant law enforcement authorities and we may
              cooperate with those authorities by disclosing your identity to
              them. In the event of such a breach, your right to use the
              Products will cease immediately.
            </p>
            <p> </p>
            <p>
              4.4 You agree not to use the Products for any purposes related to
              scientific research, analysis or evaluation of the Products
              without the express written consent of Dumb Ways to Sleep.
            </p>
            <p> </p>
            <p>5. MATERIALS OFFERED THROUGH THE PRODUCTS</p>
            <p> </p>
            <p>5.1 COPYRIGHT</p>
            <p>
              (a) All materials (including software and content whether
              downloaded or not) contained in the Products are owned by Dumb
              Ways to Sleep (or our affiliates and/or third party licensors,
              where applicable), unless indicated otherwise. You agree and
              acknowledge that the materials are valuable property and that
              other than any specific and limited license for use of such
              materials, you shall not acquire any ownership rights in or to
              such materials. The materials may not be used except as provided
              for in these Terms, and any other relevant terms and conditions
              provided to you without our prior written permission.
            </p>
            <p> </p>
            <p>
              (b) You acknowledge and agree that certain materials on or in the
              Products are the property of third party licensors and, without
              prejudice to any and all other rights and remedies available, each
              such licensor has the right to directly enforce relevant
              provisions of section 12 against you.
            </p>
            <p> </p>
            <p>
              (c) Audio or video content from Dumb Ways to Sleep not explicitly
              indicated as downloadable may not be downloaded or copied from the
              Products or any Device.
            </p>
            <p>​</p>
            <p>
              (d) The Products are not intended for your commercial use.
              Commercial advertisements, affiliate links, and other forms of
              solicitation may be removed by us without notice and may result in
              termination of privileges. You must not use any part of the
              materials used in or on the Products for commercial purposes
              without obtaining a written license to do so from us. Material
              from the Products may not be copied or distributed, or
              republished, or transmitted in any way, without our prior written
              consent. Any unauthorised use or violation of these Terms
              immediately and automatically terminates your right to use the
              Products and may subject you to legal liability. You agree not to
              use the Products for illegal purposes (including, without
              limitation, unlawful, harassing, libellous, invasion of another’s
              privacy, abusive, threatening or obscene purposes) and you agree
              that you will comply with all laws, rules and regulations related
              to your use of the Products. Appropriate legal action may be taken
              for any illegal or unauthorised use of the Products.
            </p>
            <p> </p>
            <p>
              (e) A limited amount of content may be marked and authorised for
              the user to share in their personal social channels (Facebook,
              Twitter, etc.). With respect to content made available by Dumb
              Ways to Sleep through the Products that is specifically identified
              as available for distribution by you (“Distribution Content”) as
              part of your blog or other online commentary, analysis or review
              (“User Commentary”), Dumb Ways to Sleep grants you a limited right
              to download, reproduce and distribute Distribution Content over
              the internet as part of your User Commentary. You may also modify
              such Distribution Content but only as required to technically
              enable the display and distribution of such content through your
              computer systems and over the Internet (e.g. a change in video
              format or file size) provided such modification does not
              materially alter the substance or quality of such content. Your
              display and distribution of Distribution Content may also be
              subject to other terms and conditions that are set forth in the
              description of such content in the Products, such as display and
              distribution of Distribution Content only within specified usage
              dates. You agree not to publish the Distribution Content with
              other content that is known by you to be false, inaccurate, or
              misleading or that is, or that encourages activity or conduct that
              is, unlawful, harmful, threatening, abusive, harassing, tortious,
              defamatory, vulgar, obscene, pornographic, libellous, invasive of
              another’s privacy, hateful, or racially, ethnically or otherwise
              objectionable. Distribution Content may contain trackers that
              enable us to collect information with respect to the distribution
              and consumption of such content.
            </p>
            <p> </p>
            <p>
              (f) You may not otherwise download, display, copy, reproduce,
              distribute, modify, perform, transfer, create derivative works
              from, sell or otherwise exploit any content, code, data or
              materials in the Products. If you make other use of the Products,
              or the content, code, data or materials thereon, except as
              otherwise provided above, you may violate copyright and other laws
              of Australia, other countries, as well as applicable state laws
              and may be subject to liability for such unauthorised use. Dumb
              Ways to Sleep will enforce its intellectual property rights to the
              fullest extent of the law, including the seeking of criminal
              prosecution.
            </p>
            <p> </p>
            <p>5.2 TRADEMARKS</p>
            <p>
              Dumb Ways to Sleep®, the Dumb Ways to Sleep logo and all other
              Dumb Ways to Sleep product or service marks are trademarks of Dumb
              Ways to Sleep. All intellectual property, other trademarks, logos,
              images, product and company names displayed or referred to on or
              in the Products are the property of their respective owners.
              Nothing grants you any license or right to use, alter or remove or
              copy such material. Your misuse of the trademarks displayed on the
              Products is strictly prohibited. Dumb Ways to Sleep will enforce
              its trademark rights to the fullest extent of the law, including
              the seeking of criminal prosecution.
            </p>
            <p> </p>
            <p>6. AVAILABILITY OF PRODUCTS</p>
            <p> </p>
            <p>
              6.1 Although we aim to offer you the best service possible, we
              make no promise that the Products will meet your requirements and
              we cannot guarantee that the Products will be fault free. If a
              fault occurs in the Products, please report it to us at
              support@dumbwaystosleep.com and we will review your complaint and,
              where we determine it is appropriate to do so, correct the fault.
              If the need arises, we may suspend access to the Products while we
              address the fault. We will not be liable to you if the Products
              are unavailable for a commercially reasonable period of time.
            </p>
            <p> </p>
            <p>
              6.2 Your access to the Products may be occasionally restricted to
              allow for repairs, maintenance or the introduction of new
              facilities or Products. We will restore the Products as soon as we
              reasonably can. In the event that the Products are unavailable,
              our usual Order and cancellation deadlines apply; please notify us
              of changes to your Order by emailing support@dumbwaystosleep.com.
            </p>
            <p> </p>
            <p>7. USER MATERIAL</p>
            <p> </p>
            <p>
              7.1 The Products may let you submit material to us: for example,
              you may be able to upload a photo to your profile, post subjects
              and comments in the community and comment on various matters in
              various parts of the Products. You may be able to upload video,
              images or sounds. In these Terms, we use the term “User Material”
              to refer to any publicly available material of any kind that you
              submit to us, including text, files, images, photos, video, sounds
              and musical or literary works. User Material does not include the
              account information, Product purchase, or Product use information
              which you provide in registering for and using Products.
            </p>
            <p> </p>
            <p>
              7.2 This section 7 sets out the rights and obligations that each
              of us have in connection with User Material. If you review or
              submit User Material, you are agreeing to do so in accordance with
              these Terms. If you do not want to review or submit User Material
              in accordance with these Terms, then you should not do so.
            </p>
            <p> </p>
            <p>
              7.3 We do not systematically review User Material submitted by you
              or other users. We are not responsible for the content of User
              Material provided by you or any other user. We do not necessarily
              endorse any opinion contained in such material. We make no
              warranties or representations, express or implied, about User
              Material, including as to its legality or accuracy.
            </p>
            <p> </p>
            <p>
              7.4 We reserve the right, in our sole discretion, to refuse to
              post or to remove or edit any of your User Material, or to
              restrict, suspend, or terminate your access to all or any part of
              the Products, particularly where User Material breaches this
              section 7, and we may do this with or without giving you any prior
              notice.
            </p>
            <p> </p>
            <p>
              7.5 We may link User Material or parts of User Material to other
              material, including material submitted by other users or created
              by Dumb Ways to Sleep or other third parties. We may use User
              Material for our internal business purposes, for example, to
              examine trends or categories or to promote, market or advertise
              Dumb Ways to Sleep. You acknowledge that we may indirectly
              commercially benefit from use of your User Material.
            </p>
            <p> </p>
            <p>
              7.6 Each time you submit User Material to us, you represent and
              warrant to us as follows:
            </p>
            <p>
              (a) You own your User Material or have the right to submit it, and
              in submitting it you will not be infringing any rights of any
              third party, including intellectual property rights (such as
              copyright or trade mark), privacy or publicity rights, rights of
              confidentiality or rights under contract.
            </p>
            <p> </p>
            <p>
              (b) Your User Material is not illegal, obscene, defamatory,
              threatening, pornographic, harassing, hateful, racially or
              ethnically offensive, and does not encourage conduct that would be
              considered a criminal offence, and does not give rise to civil
              liability, violate any law, or is otherwise deemed inappropriate.
            </p>
            <p> </p>
            <p>
              (c) Your User Material does not advertise any product or service
              or solicit any business.
            </p>
            <p> </p>
            <p>
              (d) Your User Material does not identify any individual (including
              by way or name, address or a still picture or video) under the age
              of 18 and if User Material identifies any individual over the age
              of 18, you have that person’s consent to being identified in
              exactly that way in your User Material; and in submitting your
              User Material you are not impersonating any other person.
            </p>
            <p> </p>
            <p>
              (e) You will not collect email addresses of users for the purpose
              of sending unsolicited email.
            </p>
            <p> </p>
            <p>
              (f) You will not engage in criminal or tortious activity,
              including fraud, spamming, sending of viruses or other harmful
              files, copyright infringement, patent infringement, or theft of
              trade secrets or attempt to impersonate another user or person.
            </p>
            <p> </p>
            <p>
              (g) You will not engage in any automated use of the system, such
              as using scripts to alter our content.
            </p>
            <p> </p>
            <p>
              (h) You will not, without authorisation, access, tamper with, or
              use non-public areas of the Products, Dumb Ways to Sleep’s
              computer systems, or the technical delivery systems of Dumb Ways
              to Sleep’s providers.
            </p>
            <p> </p>
            <p>
              (i) Except as necessary to maintain your own computer security by
              use of commercial-off-the-shelf anti-virus or anti-malware
              products, you will not attempt to probe, scan, or test the
              vulnerability of the Products or any other Dumb Ways to Sleep
              system or network or breach any security or authentication
              measures.
            </p>
            <p> </p>
            <p>
              7.7 We are entitled to identify you to third parties who claim
              that their rights have been infringed by User Material you have
              submitted.
            </p>
            <p> </p>
            <p>
              7.8 User Material is not considered to be confidential. You agree
              not to submit any content as User Material in which you have any
              expectation of privacy. We do not claim any ownership rights in
              User Material. However, by submitting User Material you hereby
              grant Dumb Ways to Sleep an irrevocable, perpetual, non-exclusive,
              royalty free, worldwide license to use, telecast, copy, perform,
              display, edit, distribute and otherwise exploit the User Material
              you post on the Products, or any portion thereof, and any ideas,
              concepts, or know how contained therein, with or without
              attribution, and without the requirement of any permission from or
              payment to you or to any other person or entity, in any manner
              (including, without limitation, for commercial, publicity, trade,
              promotional, or advertising purposes) and in any and all media now
              known or hereafter devised, and to prepare derivative works of, or
              incorporate into other works, such User Material, and to grant and
              authorise sub-licenses of the foregoing without any payment of
              money or any other form of consideration to you or to any third
              party. Dumb Ways to Sleep may include your User Material in Dumb
              Ways to Sleep’s Distribution Content that is made available to
              others through the Products. Be aware that Dumb Ways to Sleep has
              no control over User Material once it leaves the Products, and it
              is possible that others may duplicate material found on the
              Products, including, but not limited to, on other sites on the
              Internet. You represent and warrant that you own or otherwise
              control the rights to your User Material. You agree to indemnify
              Dumb Ways to Sleep and its affiliates for all claims arising from
              or in connection with any claims to any rights in your User
              Material or any damages arising from your User Material.
            </p>
            <p> </p>
            <p>
              7.9 Any inquiries, feedback, suggestions, ideas, other information
              which is not part of your use of the Products or User Material
              that you provide to us (collectively, “Submissions”) will be
              treated as non-proprietary and non-confidential. By transmitting,
              uploading, posting, e-mailing, or otherwise submitting Submissions
              to the Products, you grant, and you represent and warrant that you
              have the right to grant, to Dumb Ways to Sleep an irrevocable,
              perpetual, non-exclusive, royalty free, worldwide license to use,
              telecast, copy, perform, display, edit, distribute and otherwise
              exploit the Submissions, or any portion thereof and any ideas,
              concepts, or know how contained therein, with or without
              attribution, and without the requirement of any permission from or
              payment to you or to any other person or entity, in any manner
              (including, without limitation, for commercial, publicity, trade,
              promotional, or advertising purposes) and in any and all media now
              known or hereafter devised, and to prepare derivative works of, or
              incorporate into other works, such Submissions, and to grant and
              authorise sub-licenses of the foregoing without any payment of
              money or any other form of consideration to you or to any third
              party. You also acknowledge that your Submissions will not be
              returned to you and that Dumb Ways to Sleep has no obligation to
              acknowledge receipt of or respond to any Submissions. If you make
              a Submission, you represent and warrant that you own or otherwise
              control the rights to your Submission. You agree to indemnify Dumb
              Ways to Sleep and its affiliates for all claims arising from or in
              connection with any claims to any rights in any Submission or any
              damages arising from any Submission.
            </p>
            <p> </p>
            <p>8. LINKS TO WEBSITES/HOME PAGE</p>
            <p> </p>
            <p>
              8.1 We may provide links to other websites or services for you to
              access. You acknowledge that any access is at your sole discretion
              and for your information only. We do not review or endorse any of
              those websites or services. We are not responsible in any way
              for:(a) the availability of, (b) the privacy practices of, (c) the
              content, advertising, products, goods or other materials or
              resources on or available from, or (d) the use to which others
              make of these other websites or services. We are also not
              responsible for any damage, loss or offence caused or alleged to
              be caused by, or in connection with, the use of or reliance on
              such websites or services.
            </p>
            <p> </p>
            <p>
              8.2 You may link to our home page, provided you do so in a way
              that is fair and legal and does not damage our reputation or take
              advantage of it, but you must not establish a link in such a way
              as to suggest any form of association, approval or endorsement on
              our part where none exists. You must not establish a link from any
              website that is not owned by you. The Products must not be framed
              on any other website, nor may you create a link to any part of the
              Products unless you have written permission to do so from Dumb
              Ways to Sleep. We reserve the right to withdraw linking permission
              with written notice. The website from which you are linking must
              comply in all respects with the content standards set out in our
              acceptable use policy. If you wish to make any use of material on
              or in the Products other than that set out above, please address
              your request tosupport@dumbwaystosleep.com.
            </p>
            <p> </p>
            <p>9. PRODUCTS DISCLAIMER</p>
            <p>
              The information contained in the Products is for general
              information purposes only. While we endeavour to keep the
              information up-to-date and correct, we make no representations or
              warranties of any kind, express or implied, about the
              completeness, accuracy, reliability, suitability or availability
              with respect to the Products or the information contained on the
              Products for any purpose. Any reliance you place on such
              information is therefore strictly at your own risk.
            </p>
            <p> </p>
            <p>10. MEDICAL DISCLAIMER</p>
            <p>
              10.1 Dumb Ways to Sleep is a provider of online and mobile
              meditation content in the health & wellness space. We are not a
              health care or medical device provider, nor should our Products be
              considered medical advice. Only your physician or other health
              care provider can do that. While there is third party evidence
              from research that meditation can assist in the prevention and
              recovery process for a wide array of conditions as well as in
              improving some performance and relationship issues, Dumb Ways to
              Sleep makes no claims, representations or guarantees that the
              Products provide a therapeutic benefit.
            </p>
            <p> </p>
            <p>
              10.2 Any health information and links on the Products, whether
              provided by Dumb Ways to Sleep or by contract from outside
              providers, is provided simply for your convenience.
            </p>
            <p> </p>
            <p>
              10.3 Any advice or other materials in the Products are intended
              for general information purposes only. They are not intended to be
              relied upon and are not a substitute for professional medical
              advice based on your individual condition and circumstances. The
              advice and other materials we make available are intended to
              support the relationship between you and your healthcare providers
              and not replace it. We are not liable or responsible for any
              consequences of your having read or been told about such advice or
              other materials as you assume full responsibility for your
              decisions and actions. In particular, to the fullest extent
              permitted by law, we make no representation or warranties about
              the accuracy, completeness, or suitability for any purpose of the
              advice, other materials and information published as part of the
              Products.
            </p>
            <p> </p>
            <p>
              10.4 There have been rare reports where people with certain
              psychiatric problems like anxiety and depression have experienced
              worsening conditions in conjunction with intensive meditation
              practice. People with existing mental health conditions should
              speak with their health care providers before starting a
              meditation practice.
            </p>
            <p> </p>
            <p>11. END USER LICENSE</p>
            <p> </p>
            <p>
              11.1 Subject to the terms of this license agreement (“License
              Agreement”), as set out in this section 11, and these other Terms,
              and your payment of applicable subscription fees, Dumb Ways to
              Sleep grants you a limited, non-exclusive, revocable license to
              stream, download and make personal non-commercial use of the
              Products.
            </p>
            <p> </p>
            <p>
              11.2 The Products contain or embody copyrighted material,
              proprietary material or other intellectual property of Dumb Ways
              to Sleep or its licensors. All right, title and ownership in the
              Products remain with Dumb Ways to Sleep or its licensors, as
              applicable. The rights to download and use the Products are
              licensed to you and are not being sold to you, and you have no
              rights in them other than to use them in accordance with this
              License Agreement and our other Terms.
            </p>
            <p> </p>
            <p>
              11.3 You agree that you will not and you will not assist or permit
              any third party to:
            </p>
            <p>
              (a) Copy, store, reproduce, transmit, modify, alter,
              reverse-engineer, emulate, de-compile, or disassemble the Products
              in any way, or create derivative works of the Products;
            </p>
            <p> </p>
            <p>
              (b) Use the Products or any part of them to create any tool or
              software product that can be used to create software applications
              of any nature whatsoever;
            </p>
            <p> </p>
            <p>
              (c) Rent, lease, loan, make available to the public, sell or
              distribute the Products in whole or in part;
            </p>
            <p> </p>
            <p>
              (d) Tamper with the Products or circumvent any technology used by
              Dumb Ways to Sleep or its licensors to protect any content
              accessible through the Products;
            </p>
            <p> </p>
            <p>
              (e) Circumvent any territorial restrictions applied to the
              Products; or
            </p>
            <p> </p>
            <p>
              (f) Use the Products in a way that violates this License Agreement
              or the other Terms.
            </p>
            <p> </p>
            <p>
              11.4 You may not make the Products available to the public. The
              Products made available (in whole or in part) are owned by Dumb
              Ways to Sleep or its licensors and your use of them must be in
              accordance with these Terms.
            </p>
            <p> </p>
            <p>12. Australian, Commonwealth Copyright Act 1968</p>
            <p> </p>
            <p>
              12.1 We are committed to complying with copyright and related
              laws, and we require all users of the Products to comply with
              these laws. Accordingly, you may not store any material or content
              on, or disseminate any material or content over, the Products in
              any manner that constitutes an infringement of third party
              intellectual property rights, including rights granted by
              copyright law. Owners of copyrighted works in Australia who
              believe that their rights under copyright law have been infringed
              may take advantage of certain provisions of the Australian,
              Commonwealth Copyright Act 1968 to report alleged infringements.
              You may not post, modify, distribute, or reproduce in any way any
              copyrighted material, trademarks, or other proprietary information
              belonging to others without obtaining the prior written consent of
              the owner of such proprietary rights. It is our policy to
              terminate privileges of any user who repeatedly infringes the
              copyright rights of others upon receipt of proper notification to
              us by the copyright owner or the copyright owner’s legal agent.
            </p>
            <p> </p>
            <p>
              12.2 If you feel that a posted message is objectionable or
              infringing, we encourage you to contact us immediately. Upon our
              receipt of a proper notice of claimed infringement under the
              Australian, Commonwealth Copyright Act 1968, we will respond
              expeditiously to remove, or disable access to, the material
              claimed to be infringing and will follow the procedures specified
              in the Australian, Commonwealth Copyright Act 1968 to resolve the
              claim between the notifying party and the alleged infringer who
              provided the content in issue. Our designated agent (i.e., the
              proper party) to whom you should address such notice is listed
              below.
            </p>
            <p> </p>
            <p>
              12.3 If you believe that your work has been copied and posted on
              the Products in a way that constitutes copyright infringement,
              please provide our designated agent with the following
              information:
            </p>
            <p>
              (a) An electronic or physical signature of the person authorised
              to act on behalf of the owner of the copyright or other
              intellectual property interest;
            </p>
            <p> </p>
            <p>
              (b) A description of the copyrighted work or other intellectual
              property that you claim has been infringed;
            </p>
            <p> </p>
            <p>
              (c) A description of where the material that you claim is
              infringing is located on the Products;
            </p>
            <p> </p>
            <p>(d) Your address, telephone number, and email address;</p>
            <p> </p>
            <p>
              (e) A statement by you that you have a good faith belief that the
              disputed use is not authorised by the copyright or intellectual
              property owner, its agent, or the law; and
            </p>
            <p> </p>
            <p>
              (f) A statement by you, made under penalty of perjury, that the
              information contained in your report is accurate and that you are
              the copyright or intellectual property owner or authorised to act
              on the copyright or intellectual property owner’s behalf.
            </p>
            <p> </p>
            <p>
              (g) Our designated agent for notice of claims of copyright
              infringement can be reached as follows:
            </p>
            <p>By E-Mail:support@dumbwaystosleep.com</p>
            <p> </p>
            <p>13. GENERAL TERMS AND CONDITIONS</p>
            <p> </p>
            <p>13.1 ASSIGNMENT BY US</p>
            <p>
              Dumb Ways to Sleep may transfer its rights and obligations under
              these Terms to any company, firm or person at any time if it does
              not materially affect your rights under it. You may not transfer
              your rights or obligations under these Terms to anyone else. These
              Terms are personal to you and no third party is entitled to
              benefit under these Terms except as set out here.
            </p>
            <p> </p>
            <p>13.2 INDEMNITY BY YOU</p>
            <p>
              You agree to defend, indemnify and hold Dumb Ways to Sleep and its
              directors, officers, members, investors, managers, employees and
              agents harmless from any and all claims, liabilities, costs and
              expenses, including reasonable attorneys’ fees, arising in any way
              from your use of the Products, your placement or transmission of
              any message, content, information, software, or other submissions
              through the Products, or your breach or violation of the law or of
              these Terms. Dumb Ways to Sleep reserves the right, at its own
              expense, to assume the exclusive defence and control of any matter
              otherwise subject to indemnification by you, and in such case, you
              agree to cooperate with Dumb Ways to Sleep defence of such claim.
            </p>
            <p> </p>
            <p>13.3 WARRANTIES AND LIMITATIONS</p>
            <p>
              (a) We warrant to you that any Product purchased from us will, on
              delivery, conform in all material respects with its description
              and be of reasonably satisfactory quality.
            </p>
            <p> </p>
            <p>
              (b) We warrant that we will use reasonable skill and care in
              making the Products available to you during your subscription.
            </p>
            <p> </p>
            <p>
              (c) Nothing in this sections 13.3 or otherwise in these Terms
              shall exclude or in any way limit Dumb Ways to Sleep’s liability
              for: fraud; death or personal injury caused by negligence; or
              liability to the extent the same may not be excluded or limited as
              a matter of law.
            </p>
            <p> </p>
            <p>
              (d) The Products and their content are otherwise provided on an
              “as is” basis and we make no representations or warranties of any
              kind with respect to them, including as to the accuracy,
              completeness or currency of the Products or their content. We
              assume no liability or responsibility for any errors or omissions
              in the content of the Products, or any failures, delays, or
              interruptions in the provision of the Products. We disclaim and
              exclude any express or implied warranties or representations,
              including any warranties as to merchantability or fitness for a
              particular purpose of the Products to the broadest extent
              permitted by law. We make no warranties or representations,
              express or implied, as to the timeliness, accuracy, quality,
              completeness or existence of the content and information posted on
              the Products. We make no warranties or representations, express or
              implied, for technical accessibility, fitness or flawlessness of
              the Products. We make no warranties or representations that your
              use of content and information posted on the Products will not
              infringe rights of third parties.
            </p>
            <p> </p>
            <p>
              (e) All conditions, warranties and other terms which might
              otherwise be implied by statute, common law or the law of equity
              are, to the extent permitted by law, excluded.
            </p>
            <p> </p>
            <p>13.4 NO WAIVER</p>
            <p>
              If we delay exercising or fail to exercise or enforce any right
              available to us under these Terms, such delay or failure does not
              constitute a waiver of that right or any other rights under these
              Terms.
            </p>
            <p> </p>
            <p>13.5 FORCE MAJEURE</p>
            <p>
              We will not be liable to you for any lack of performance, or the
              unavailability or failure, of the Products, or for any failure or
              delay by us to comply with these Terms, where such lack,
              unavailability or failure arises from any cause beyond our
              reasonable control.
            </p>
            <p> </p>
            <p>13.6 INTERPRETATION</p>
            <p>
              In these Terms, unless the context requires otherwise: i) any
              phrase introduced by the words “including”, “include”, “in
              particular”, “for example” or any similar expression shall be
              construed as illustrative only and shall not be construed as
              limiting the generality of any preceding words; and ii) references
              to the singular include the plural and to the masculine include
              the feminine, and in each case vice versa.
            </p>
            <p> </p>
            <p>13.7 ELECTRONIC COMMUNICATIONS</p>
            <p>
              (a) Applicable laws require that some of the information or
              communications we send to you should be in writing. When using the
              Products, you agree to transact with us electronically, and that
              communication with us will be mainly electronic. We will contact
              you by e-mail or provide you with information by posting notices
              on the Products. You agree to this electronic means of
              communication and you acknowledge that all contracts, notices,
              information and other communications that we provide to you
              electronically comply with any legal requirement that such
              communications be in writing.
            </p>
            <p> </p>
            <p>
              (b) In order to retain a copy, please select “Print,” and select
              the appropriate printer. If you do not have a printer, you can
              copy the text and the underlying agreement(s) and paste them into
              a new document in a word processor or a text editor on your
              computer and save the text.
            </p>
            <p> </p>
            <p>
              (c) You have the right to receive a paper copy of the
              communications. To receive a paper copy, please request it by
              emailing us at support@dumbwaystosleep.com
            </p>
            <p> </p>
            <p>
              (d) We may charge you a reasonable service charge to mail you a
              paper copy of any communication. We will either include such
              service charge on our fee schedule or we will first inform you of
              the charge and provide you with the choice as to whether you still
              want us to send you a paper copy. Please be sure to state that you
              are requesting a copy of the particular communication.
            </p>
            <p> </p>
            <p>
              (e) To receive and view an electronic copy of the communications
              you must have the following equipment and software:
            </p>
            <p> </p>
            <p>
              (i) A personal computer or other device which is capable of
              accessing the Internet. Your access to this page verifies that
              your system/device meets these requirements.
            </p>
            <p> </p>
            <p>
              (ii) an Internet web browser which is capable of supporting
              128-bit SSL encrypted communications, JavaScript, and cookies.
              Your system or device must have 128-bit SSL encryption software.
              Your access to this page verifies that your browser and encryption
              software/device meet these requirements.
            </p>
            <p> </p>
            <p>
              (f) To retain a copy, you must either have a printer connected to
              your personal computer or other device or, alternatively, the
              ability to save a copy through use of printing service or software
              such as Adobe Acrobat®. If you have a word processor or text
              editor program on your computer, then you can also copy the text
              and paste the text into a new document in the word processor or
              text editor and save the text.
            </p>
            <p> </p>
            <p>
              (g) You can also contact us via email at
              support@dumbwaystosleep.com to withdraw your consent to receive
              any future communications electronically, including if the system
              requirements described above change and you no longer possess the
              required system. If you withdraw your consent, we may terminate
              your use of the Products.
            </p>
            <p> </p>
            <p>
              (h) We reserve the right, in our sole discretion, to discontinue
              the provision of your electronic communications, or to terminate
              or change the terms and conditions on which we provide electronic
              communications. We will provide you with notice of any such
              termination or change as required by law.
            </p>
            <p> </p>
            <p>13.8 NOTICES</p>
            <p>
              Unless otherwise specifically indicated, all notices given by you
              to us must be given to Dumb Ways to Sleep at
              support@dumbwaystosleep.com. We may give notice to you at the
              e-mail address you provide to us when you register, or in any of
              the ways specified in section 13.7 above. Notice will be deemed
              received and properly served immediately when posted on the
              Products or when an e-mail or other electronic communication is
              sent. In proving the service of any notice via email, it will be
              sufficient to prove that such e-mail was sent to the specified
              e-mail address of the addressee.
            </p>
            <p> </p>
            <p>13.9 ENTIRE AGREEMENT</p>
            <p>
              These Terms and any document expressly referred to in them
              constitute the whole agreement between us and supersede all
              previous discussions, correspondence, negotiations, previous
              arrangement, understanding or agreement between us relating to
              their subject matter. We each acknowledge that neither of us
              relies on, or will have any remedies in respect of, any
              representation or warranty (whether made innocently or
              negligently) that is not set out in these Terms or the documents
              referred to in them. Each of us agrees that our only liability in
              respect of those representations and warranties that are set out
              in this agreement (whether made innocently or negligently) will be
              for breach of contract. Nothing in this section limits or excludes
              any liability for fraud.
            </p>
            <p> </p>
            <p>13.10 THIRD PARTY RIGHTS</p>
            <p>
              A person who is not party to these Terms will not, subject to
              section 12 (Australian, Commonwealth Copyright Act 1968), have any
              rights under or in connection with these Terms.
            </p>
            <p> </p>
            <p>13.11 OUR LIABILITY</p>
            <p>
              (a) We will use reasonable endeavours to remedy faults in the
              Products. If we fail to comply with these Terms, we will be liable
              to you only for the purchase price of the Products in question. In
              addition, we will not be liable for:
            </p>
            <p> </p>
            <p>
              (i) Faulty operation of computers during the registration process
              or during completion of a subscription or during the transmission
              of any data and/or for incorrect or overly slow transmission of
              data by the internet provider and/or any damage that occurs due to
              information submitted by you not being received by us or not being
              received promptly or not being considered, as a consequence of
              technical faults with our software or hardware (whether or not
              they are within or outside of our control).
            </p>
            <p> </p>
            <p>
              (ii) Any loss or damage due to viruses or other malicious software
              that may infect your Device, computer equipment, software, data or
              other property caused by you accessing, using or downloading from
              the Products, or from transmissions via emails or attachments
              received from us.
            </p>
            <p> </p>
            <p>
              (iii) Any use of websites linked to the Products but operated by
              third parties.
            </p>
            <p> </p>
            <p>
              (b) To the extent permitted by law, Dumb Ways to Sleep and its
              affiliates, suppliers, clients, or licensors (collectively, the
              “Protected Entities”) shall not be liable for any consequential,
              exemplary or punitive damages arising from, or directly or
              indirectly related to, the use of, or the inability to use, the
              Products or the content, materials and functions related thereto,
              your provision of information via the Products, or lost business
              or lost sales, or any errors, viruses or bugs contained in the
              Products, even if such Protected Entity has been advised of the
              possibility of such damages. In no event shall the Protected
              Entities be liable for or in connection with any content posted,
              transmitted, exchanged or received by or on behalf of any user or
              other person on or through the Products. In no event shall the
              total aggregate liability of the Protected Entities to you for all
              damages, losses, and causes of action (whether in contract or
              tort, including, but not limited to, negligence or otherwise)
              arising from these terms of use or your use of the Products
              exceed, in the aggregate, the amount, if any, paid by you to Dumb
              Ways to Sleep for your use of the Products.
            </p>
            <p> </p>
            <p>13.12 ARBITRATION</p>
            <p>
              PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY, AS THEY REQUIRE
              YOU TO ARBITRATE DISPUTES WITH DUMB WAYS TO SLEEP, AND LIMIT THE
              MANNER IN WHICH YOU CAN SEEK RELIEF FROM DUMB WAYS TO SLEEP.
            </p>
            <p> </p>
            <p>
              (a) Applicability of Arbitration Agreement. All disputes arising
              out of, relating to, or in connection with these Terms or your use
              of the Products that cannot be resolved informally or in small
              claims court will be resolved through binding arbitration on an
              individual basis, except that you and Dumb Ways to Sleep are not
              required to arbitrate any dispute in which either party seeks
              equitable relief for the alleged unlawful use of copyrights,
              trademarks, trade names, logos, trade secrets, or patents.
            </p>
            <p> </p>
            <p>
              (b) Arbitration Rules. The Federal Arbitration Act governs the
              interpretation and enforcement of this dispute-resolution
              provision. Arbitration will be initiated through the Australian
              Centre for International Commercial Arbitration (ACICA). If the
              ACICA is not available to arbitrate, the parties will select an
              alternative arbitral forum. The rules of the arbitral forum will
              govern all aspects of this arbitration, except to the extent those
              rules conflict with these Terms. The ACICA Consumer Arbitration
              Rules (“ACICA Rules”) governing the arbitration are available
              online at https://acica.org.au/ or by calling the ACICA at (02)
              9223 1099. The arbitration will be conducted by a single neutral
              arbitrator.
            </p>
            <p> </p>
            <p>
              If the claim is for $10,000 or less, the party initiating the
              arbitration may choose whether the arbitration will be conducted
              (1) solely on the basis of documents submitted to the arbitrator;
              (2) through a non-appearance based telephonic hearing; or (3) by
              an in-person hearing as established by the ACICA Rules in the
              county of your billing address. In the case of an in-person
              hearing, the proceedings will be conducted at a location which is
              reasonably convenient for both parties with due consideration of
              the ability to travel and other pertinent circumstances. If the
              parties are unable to agree on a location, the determination will
              be made by the arbitration institution.
            </p>
            <p>
              Your arbitration fees and your share of arbitrator compensation
              will be limited to those fees set forth in the ACICA Rules with
              the remainder paid by Dumb Ways to Sleep. If the arbitrator finds
              that either the substance of your claim or the relief sought in
              the arbitration is frivolous or brought for an improper purpose
              (as measured by the standards set forth in Federal Rule of Civil
              Procedure 11(b)), then the payment of all fees will be governed by
              the ACICA Rules. In such case, you agree to reimburse Dumb Ways to
              Sleep for all monies previously disbursed by it that are otherwise
              your obligation to pay under the ACICA Rules. Regardless of the
              manner in which the arbitration is conducted, the arbitrator shall
              issue a reasoned written decision sufficient to explain the
              essential findings and conclusions on which the decision and
              award, if any, are based. The arbitrator may make rulings and
              resolve disputes as to the payment and reimbursement of fees or
              expenses at any time during the proceeding and upon request from
              either party made within 14 days of the arbitrator’s ruling on the
              merits.
            </p>
            <p> </p>
            <p>
              (c) Authority of Arbitrator. The arbitrator will decide the
              jurisdiction of the arbitrator and the rights and liabilities, if
              any, of you and Dumb Ways to Sleep. The dispute will not be
              consolidated with any other matters or joined with any other cases
              or parties. The arbitrator will have the authority to grant
              motions dispositive of all or part of any claim or dispute. The
              arbitrator will have the authority to award all remedies available
              under applicable law, the arbitral forum's rules, and the Terms.
              The arbitrator has the same authority to award relief on an
              individual basis that a judge in a court of law would have. The
              award of the arbitrator is final and binding upon you and Dumb
              Ways to Sleep.
            </p>
            <p>
              (d) Jury Trial Waiver. You and Dumb Ways to Sleep waive any
              constitutional and statutory rights to go to court and have a
              trial in front of a judge or a jury. Rather, you and Dumb Ways to
              Sleep elect to have claims and disputes resolved by arbitration.
              In any litigation between you and Dumb Ways to Sleep over whether
              to vacate or enforce an arbitration award, you and Dumb Ways to
              Sleep waive all rights to a jury trial, and elect instead to have
              the dispute be resolved by a judge.
            </p>
            <p> </p>
            <p>
              (e) Class Action Waiver. WHERE PERMITTED UNDER THE APPLICABLE LAW,
              YOU AND DUMB WAYS TO SLEEP AGREE THAT EACH MAY BRING CLAIMS
              AGAINST THE OTHER ONLY IN YOUR OR OUR INDIVIDUAL CAPACITY AND NOT
              AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
              CONSOLIDATED ACTION. If, however, this waiver of class or
              consolidated actions is deemed invalid or unenforceable, neither
              you nor Dumb Ways to Sleep are entitled to arbitration; instead
              all claims and disputes will be resolved in a court as set forth
              in section 13.13 below.
            </p>
            <p> </p>
            <p>
              (f) Opt-out. YOU MAY OPT-OUT OF THIS ARBITRATION AGREEMENT. If you
              do so, neither you nor Dumb Ways to Sleep can force the other to
              arbitrate. To opt-out, you must notify Dumb Ways to Sleep in
              writing no later than 30 days after first becoming subject to this
              arbitration agreement. Your notice must include your name and
              address, and the email address you used to set up your Dumb Ways
              to Sleep account (if you have one), and an unequivocal statement
              that you want to opt-out of this arbitration agreement. You must
              send your opt-out notice to:support@dumbwaystosleep.com.
            </p>
            <p> </p>
            <p>
              (g) Small Claims Court. Notwithstanding the foregoing, either you
              or Dumb Ways to Sleep may bring an individual action in small
              claims court.
            </p>
            <p> </p>
            <p>
              (h) Arbitration Agreement Survival. This arbitration agreement
              will survive the termination of your relationship with Dumb Ways
              to Sleep.
            </p>
            <p> </p>
            <p>13.13 EXCLUSIVE VENUE</p>
            <p>
              To the extent the parties are permitted under these Terms to
              initiate litigation in a court, both you and Dumb Ways to Sleep
              agree that all claims and disputes arising out of or relating to
              the Terms or the use of the Products will be litigated exclusively
              in Australia If, however, that court would lack original
              jurisdiction over the litigation, then all claims and disputes
              arising out of or relating to the Terms or the use of the Products
              will be litigated exclusively in the Federal court of Australia.
              You and Dumb Ways to Sleep consent to the personal jurisdiction of
              both courts.
            </p>
            <p> </p>
            <p>13.14 CHOICE OF LAW</p>
            <p>
              Except to the extent they are preempted by Australian. federal
              law, the laws of Australia, other than its conflict-of-laws
              principles, govern these Terms and any disputes arising out of or
              relating to these Terms or their subject matter, including tort
              claims.
            </p>
            <p> </p>
            <p>13.15 SEVERABILITY</p>
            <p>
              If any provision of these Terms is found unenforceable, then that
              provision will be severed from these Terms and not affect the
              validity and enforceability of any remaining provisions.
            </p>
            <p></p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
