export const accessToken = "access_token";
export const refreshToken = "refresh_token";

export async function getCookie(key) {
  return localStorage.getItem(key);
}

export async function setCookie(key, value) {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key);
  }
  return localStorage.setItem(key, value);
}

export async function removeCookie(key) {
  return localStorage.removeItem(key);
}

export default { setCookie, getCookie, removeCookie };
