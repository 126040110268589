import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              PlaySide Studios built the app as an Ad Supported app. This
              SERVICE is provided by PlaySide Studios at no cost and is intended
              for use as is.
            </p>
            <p>
              This page is used to inform website visitors regarding our
              policies with the collection, use, and disclosure of Personal
              Information if anyone decided to use our Service. ​ If you choose
              to use our Service, then you agree to the collection and use of
              information in relation to this policy. The Personal Information
              that we collect is used for providing and improving the Service.
              We will not use or share your information with anyone except as
              described in this Privacy Policy. ​ The terms used in this Privacy
              Policy have the same meanings as in our Terms and Conditions,
              which is accessible at unless otherwise defined in this Privacy
              Policy.
            </p>
            ​<h1>Information Collection and Use</h1>​
            <p>
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information. The information that we request is will be retained
              by us and used as described in this privacy policy. ​ The app does
              use third party services that may collect information used to
              identify you. ​ Link to privacy policy of third party service
              providers used by the app
            </p>
            ​<h1>Personalized Data</h1>​
            <p>
              Depending on where you are, you may have certain rights with
              respect to your information, such as rights of access, to receive
              a copy of your data, or to delete your data or restrict or object
              to our processing of your data.
            </p>
            <p>
              For both console and mobile Game, we keep your personal data as
              long as necessary to ensure that we can continue to offer you the
              Game until:
              <br />
              (i) you ask us to delete your personal data that is processed for
              our Game Service, or
              <br />
              (ii) the provision of our Game Service to the market is
              terminated.
            </p>
            <h2>Exceptions to right to Erasure</h2>
            <p>
              The data retention period for your personal data by us after the
              events of (i) or (ii) occur is as follows:
              <br />
              - Period necessary to respond to legal obligations
              <br />
              - Period necessary for dispute resolution
              <br />
              Contact{" "}
              <a href="mailto:support@playside.zendesk.com">
                support@playside.zendesk.com
              </a>{" "}
              for data removal or for an export of what data we hold on your
              activity.
            </p>
            ​
            <a href="https://www.apple.com/au/legal/privacy/en-ww/">
              Apple App Store
            </a>
            <a href="https://www.google.com/policies/privacy/">
              Google Play Services
            </a>
            <h1>Log Data</h1>​ We want to inform you that whenever you use our
            Service, in a case of an error in the app we collect data and
            information (through third party products) on your phone called Log
            Data. This Log Data may include information such as your device
            Internet Protocol (“IP”) address, device name, operating system
            version, the configuration of the app when utilizing our Service,
            the time and date of your use of the Service, and other statistics.
            ​<h1>Cookies</h1>​ Cookies are files with a small amount of data
            that are commonly used as anonymous unique identifiers. These are
            sent to your browser from the websites that you visit and are stored
            on your device's internal memory. ​ This Service does not use these
            “cookies” explicitly. However, the app may use third party code and
            libraries that use “cookies” to collect information and improve
            their services. You have the option to either accept or refuse these
            cookies and know when a cookie is being sent to your device. If you
            choose to refuse our cookies, you may not be able to use some
            portions of this Service. ​<h1>Service Providers</h1>​ We may employ
            third-party companies and individuals due to the following reasons:
            ​ To facilitate our Service; To provide the Service on our behalf;
            To perform Service-related services; or To assist us in analyzing
            how our Service is used. ​ We want to inform users of this Service
            that these third parties have access to your Personal Information.
            The reason is to perform the tasks assigned to them on our behalf.
            However, they are obligated not to disclose or use the information
            for any other purpose. ​<h1>Security</h1>​ We value your trust in
            providing us your Personal Information, thus we are striving to use
            commercially acceptable means of protecting it. But remember that no
            method of transmission over the internet, or method of electronic
            storage is 100% secure and reliable, and we cannot guarantee its
            absolute security. ​<h1> Links to Other Sites</h1>​ This Service may
            contain links to other sites. If you click on a third-party link,
            you will be directed to that site. Note that these external sites
            are not operated by us. Therefore, we strongly advise you to review
            the Privacy Policy of these websites. We have no control over and
            assume no responsibility for the content, privacy policies, or
            practices of any third-party sites or services. ​ ​
            <h1> Changes to This Privacy Policy</h1>​ We may update our Privacy
            Policy from time to time. Thus, you are advised to review this page
            periodically for any changes. We will notify you of any changes by
            posting the new Privacy Policy on this page. These changes are
            effective immediately after they are posted on this page. ​
            <h1> Contact Us</h1>​ If you have any questions or suggestions about
            our Privacy Policy, do not hesitate to contact us.
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
